/* eslint-disable @typescript-eslint/explicit-module-boundary-types, react/destructuring-assignment, @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwlLight';

import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { BackgroundSilver, BackgroundDarken } from '../../components/components';
import NavBar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import Quote from '../../components/quote/quote';
import { Button } from '../../components/buttons/index';
import BlogContentContainer from '../../components/blog-content-container/blog-content-container';
import { QD } from '../../types/utililty-types';
import {
  AuthorBox,
  AuthorImageBox,
  AuthorName,
  AuthorPicture,
  Container,
  CoverPicture,
  EmbeddedImage,
  HorizontalRuler,
  NameContainer,
  NameDateWrapper,
  PageTitle,
  PostDate,
  PostFooterText,
  Subtitle,
  TitleContainer,
  TitleGrid,
  VerticalSeparator,
  Pre,
  Line,
  LineNo,
  LineContent,
  BlogContactButton,
} from './components';

type BlogPostData = {
  contentfulBlogPost: {
    title: string,
    contentMedia: {
      file: {
        url: string,
      },
      gatsbyImageData: IGatsbyImageData,
    }[],
    shortText: {
      shortText: string,
    },
    author: {
      name: string,
      picture: {
        file: {
          url: string,
        },
        gatsbyImageData: IGatsbyImageData,
      },
    }[],
    subtitle: string,
    link: string,
    creationDate: string,
    blogContent: RenderRichTextData<ContentfulRichTextGatsbyReference>,
  },
};

const BlogPostPage = (
  {
    data: {
      contentfulBlogPost: {
        title,
        shortText,
        blogContent,
        subtitle,
        contentMedia,
        author,
        creationDate,
      },
    },
  }: QD<BlogPostData>,
) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node): React.ReactElement | null => {
        const { __typename } = node.data.target;
        if (__typename === 'ContentfulCodeSnippet') {
          return (
            <Highlight
              {...defaultProps}
              code={node.data?.target?.codeBlock?.codeBlock}
              language={node.data?.target?.snippetLanguage}
              theme={theme}
            >
              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Pre className={className} style={style}>
                  {tokens.map((line, i) => (
                    <Line key={i} {...getLineProps({ line, key: i })}>
                      <LineNo>{i + 1}</LineNo>
                      <LineContent>
                        {line.map((token, key) => (
                          <span key={key} {...getTokenProps({ token, key })} />
                        ))}
                      </LineContent>
                    </Line>
                  ))}
                </Pre>
              )}
            </Highlight>);
        }
        return null;
      },
      [BLOCKS.OL_LIST]: (_, children) => (
        <ol>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {children}
        </ol>
      ),
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {children}
        </ul>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <p>{children}</p>;
      },
      [BLOCKS.QUOTE]: (_, children) => <Quote value={children} />,

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const {
          data: {
            target: {
              title: { de: titleDe },
              description: { de: descriptionDe },
              gatsbyImageData,
            },
          },
        } = node;
        return (
          <EmbeddedImage
            title={titleDe || null}
            alt={descriptionDe || null}
            image={getImage(gatsbyImageData)!}
            loading='lazy'
          />);
      },
    },
  };

  return (
    <Layout>
      <Seo
        title={title}
        description={shortText.shortText}
        image={contentMedia?.[0]?.file?.url}
        contentType='blog'
      />
      <NavBar light={false} />
      <BackgroundSilver>
        <BackgroundDarken>
          <Container>
            <CoverPicture image={getImage(contentMedia?.[0]?.gatsbyImageData)!} alt={`image for Blogpost ${title}`} />
            <TitleGrid>
              <AuthorBox>
                <AuthorImageBox>
                  {author.map((currentAuthor, index) => (
                    <AuthorPicture key={index} image={currentAuthor.picture.gatsbyImageData} alt={`photo of ${currentAuthor.name}`} />
                  ))}
                </AuthorImageBox>
                <NameDateWrapper>
                  <NameContainer>
                    {author.map((currentAuthor, index) => (
                      <AuthorName key={index}>{currentAuthor.name}</AuthorName>
                    ))}
                  </NameContainer>
                  <PostDate>{new Date(creationDate).toLocaleDateString('de')}</PostDate>
                </NameDateWrapper>
              </AuthorBox>
              <VerticalSeparator />
              <TitleContainer>
                <Subtitle>{subtitle}</Subtitle>
                <PageTitle>{title}</PageTitle>
              </TitleContainer>
            </TitleGrid>
            <BlogContentContainer>
              {blogContent && renderRichText(blogContent, options)}
            </BlogContentContainer>
            <BlogContactButton
              primary
              onClick={() => {
                navigate('/kontakt');
              }}
            >
              Nimm Kontakt mit uns auf

            </BlogContactButton>
            <HorizontalRuler />
            <PostFooterText>Noch nicht genug? Lies mehr von uns!</PostFooterText>
            <Button
              width={285}
              type='button'
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              style={{ marginBottom: '50px' }}
              onClick={async () => navigate(`/blog`)}
            >
              Zurück zur Übersicht
            </Button>
          </Container>
          <Footer />
        </BackgroundDarken>
      </BackgroundSilver>
    </Layout>

  );
};

export default BlogPostPage;

export const query = graphql`
  query blogPost($link: String) {
    contentfulBlogPost(link: {eq: $link}) {
      title
      contentMedia {
        file {url}
        gatsbyImageData(
          width: 3000,
          height: 1000,
          quality: 100,
            placeholder: BLURRED
        )
      }
      shortText {
        shortText
      }
      author {
        name
        picture {
          file {
            url
          }
          gatsbyImageData(
            width: 150,
            quality: 50,
            placeholder: BLURRED
          )
        }
      }
      subtitle
      link
      creationDate
      blogContent {
        raw
        references {
        __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData
            file {
              url
            }
          }
          ... on ContentfulCodeSnippet {
            contentful_id
            snippetLanguage
            codeBlock {
              codeBlock
            }
          }
        }
      }
    }
  }
`;
