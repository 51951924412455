import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const BlogContentContainer = styled.div`
  place-self: center;

  ${breakpoints.xs`
    margin-bottom: 25px;
    width: 100%;
  `}
  ${breakpoints.lg`
    max-width: 750px;
    margin-bottom: 75px;
  `}
  color: ${({ theme }) => theme.color.basic.black};
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;

  > h1, h2 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.5px;

    ${breakpoints.xs`
    font-size: 22px;
    margin: 35px 0 20px;
  `}
    ${breakpoints.lg`
    font-size: 32px;
    margin: 50px 0 30px;
  `}
  }

  > h3 {
    font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.5px;

    ${breakpoints.xs`
    font-size: 20px;
    margin: 35px 0 15px;
  `}
    ${breakpoints.lg`
    font-size: 28px;
    margin: 50px 0 20px;
  `}
  }

  > p {
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.56px;

    ${breakpoints.xs`
      margin-top: 15px;
  `}
    ${breakpoints.lg`
      margin-top: 20px;
  `}
  }

  > br {
    line-height: 1.56;
  }

  > ol, ul, li {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.56px;
    list-style-position: outside;
    margin-left: 10px;

    > p {
      margin-left: 5px;
    }
  }

  > ol, ul {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }

  code {
    font-family: 'Courier';
    display: inline-block;
    background-color: ${({ theme }) => theme.color.basic.silver};
    padding: 0 7.5px;
    border-radius: 5px;
    color: ${({ theme }) => theme.color.basic.black};
    font-size: 16px;
  }

  a {
    padding-bottom: 1px;
    text-decoration: none;
    border-bottom: ${({ theme }) => theme.color.butterscotch.main} 1px solid;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  a:hover {
    color: #5c5c5c;
  }
`;

export default BlogContentContainer;
