import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const QuoteContainer = styled.div`
  > * + * {
    margin-left: 20px;
  }

  ::before {
    content: '\\201D';
    font-size: 30px;
  }

  ::after {
    content: '\\201C';
    font-size: 30px;
    align-items: end;
    align-content: end;
    align-self: end;
    margin: 0 0 -15px;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  opacity: 0.7;
  margin-bottom: 30px;

`;

const VerticalRuler = styled.span`
  width: 2px;
  opacity: 0.4;
  background: ${({ theme }) => theme.color.basic.black};
  margin: 0 20px 0 50px;
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.lg`
    display: inline-block;
  `}
`;

const QuoteText = styled.p`
  place-self: center;
  color: ${({ theme }) => theme.color.basic.black};
  font-family: ${({ theme }) => theme.typography.akkurat}, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 5px 10px;

  ${breakpoints.xs`
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.56px;
  `}
  ${breakpoints.lg`
    line-height: 1.56;
    font-size: 18px;
    letter-spacing: 0.56px;
    max-width: 600px;
  `}
`;

export {
  QuoteText,
  QuoteContainer,
  VerticalRuler,
};
