import React from 'react';

import { QuoteText, QuoteContainer } from './components';

type QuoteProps = {
  value: React.ReactNode,
};
const Quote: React.FC<QuoteProps> = ({ value }) => (
  <QuoteContainer>
    <QuoteText>{value}</QuoteText>
  </QuoteContainer>

);

export default Quote;
