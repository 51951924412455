import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import breakpoints from '../../styles/breakpoints';
import { Button } from '../../components/buttons';

const AuthorBox = styled.div`
  display: grid;
  align-self: flex-start;

  ${breakpoints.xs`
    margin-top: 20px;
    align-self: start;
    align-items: start;
    grid-template-rows: auto auto;
    place-self: start;
    justify-items: start;
  `}
  ${breakpoints.md`
    grid-template-columns: auto auto;
    grid-template-rows: unset;
    align-items: center;
    `}
  ${breakpoints.lg`
    justify-items: end;
    margin-top: unset;
    align-self: flex-start;
    align-items: unset;
    grid-template-columns: unset;
    grid-template-rows: auto auto;
  `}
`;

const AuthorImageBox = styled.div`
  display: flex;
  margin: 0 -5px 5px 0;
  flex-direction: row-reverse;

  > * + * {
    margin-right: -20px;
  }
`;

const AuthorName = styled.p`
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.38;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.color.basic.black};
`;

const AuthorPicture = styled(GatsbyImage)`
  border-radius: 100%;
  border: 5px solid #d4ebeb;

  ${breakpoints.xs`
    width: 60px;
    height: 60px;
  `}
  ${breakpoints.lg`
    width: 70px;
    height: 70px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoints.xs`
    grid-template-rows: 100px 1fr;
    padding: 10vh 10vw 27.5vh;
  `}
  ${breakpoints.sm`
    grid-template-rows: 100px 1fr;
    padding: 10vh 10vw 25vh;
  `}
  ${breakpoints.lg`
    grid-template-rows: 200px 1fr;
    padding: 10vh 15vw 20vh;
  `}
`;

const CoverPicture = styled(GatsbyImage)`
  ${breakpoints.xs`
    height: 300px;
    margin-bottom: 25px;
  `}
  ${breakpoints.lg`
    height: 450px;
    margin-bottom: 75px;
  `}
  width: 100%;
  border-radius: 15px;
`;

const BlogContactButton = styled(Button)`
  place-self: center;
  margin-bottom: 35px;
`;

const EmbeddedImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 850px;
  object-fit: cover;
  border-radius: 15px;
  ${breakpoints.xs`
    margin: 30px 0 0 0;
  `}
  ${breakpoints.lg`
    margin: 45px 0 0 0;
  `}
`;

const HorizontalRuler = styled.hr`
  ${breakpoints.xs`
    margin-bottom: 25px;
  `}
  ${breakpoints.lg`
    margin-bottom: 75px;
  `}
  opacity: 0.4;
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.color.basic.black};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const NameDateWrapper = styled.div`
  ${breakpoints.xs`
    text-align: left;
    margin-left: 10px;
    margin-bottom: 10px;
  `}
  ${breakpoints.md`
    margin-bottom: unset;
  `}
  ${breakpoints.lg`
    text-align: end;
    margin-left: unset;
  `}
`;

const PageTitle = styled.h1`
  ${breakpoints.xs`
    margin-top: 15px;
    font-size: 30px;
    line-height: normal;
    letter-spacing: 0.5px;
    hyphens: auto;
  `}
  ${breakpoints.lg`
    margin-top: unset;
    font-size: 46px;
    line-height: 1.46;
    letter-spacing: 2px;
    hyphens: unset;
  `}
  ${breakpoints.xl`
    font-size: 56px;
    hyphens: unset;
  `}
  align-self: stretch;
  font-size: 56px;
  line-height: 1.46;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  color: ${({ theme }) => theme.color.basic.black};
`;

const PostDate = styled.p`
  font-family: ${({ theme }) => theme.typography.akkurat};
  font-size: 14px;
  line-height: 1.38;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.color.butterscotch.main};
  margin-top: 10px;
`;

const PostFooterText = styled.p`
  ${breakpoints.xs`
    font-size: 10px;
    letter-spacing: 2px;
    margin-bottom: 25px;
  `}
  ${breakpoints.lg`
    font-size: 24px;
    letter-spacing: 5px;
    margin-bottom: 50px;
  `}
  opacity: 0.8;
  font-family: ${({ theme }) => theme.typography.mosk.bold}, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.67;
  color: ${({ theme }) => theme.color.basic.black};
`;

const Subtitle = styled.h2`
  ${breakpoints.xs`
    font-size: 10px;
    line-height: normal;
    letter-spacing: 2px;
  `}
  ${breakpoints.lg`
    font-size: 16px;
    line-height: 1.67;
    letter-spacing: 5px;
    font-weight: 800;
  `}
  ${breakpoints.xl`
    font-size: 18px;
  `}
  font-family: ${({ theme }) => theme.typography.akkurat};
  color: ${({ theme }) => theme.color.butterscotch.main};
  text-transform: uppercase;
`;

const TitleContainer = styled.div`
  max-width: 1800px;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${breakpoints.lg`
    height: auto;
  `}
`;

const TitleGrid = styled.div`
  display: flex;
  align-items: flex-start;
  ${breakpoints.xs`
    flex-direction: column-reverse;
    margin-bottom: 25px;
  `}
  ${breakpoints.sm`
    align-items: start;
  `}
  ${breakpoints.lg`
    flex-direction: row;
    min-height: 200px;
    margin-bottom: 50px;
    align-items: flex-start;
  `}
`;

const VerticalSeparator = styled.span`
  width: 4px;
  height: 190px;
  opacity: 0.4;
  background: ${({ theme }) => theme.color.basic.black};
  margin: 0 50px 0 20px;
  ${breakpoints.xs`
    display: none;
  `}
  ${breakpoints.lg`
    display: block;
  `}
`;

const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  border-radius: 15px;
  overflow-y: auto;

  & .token-line {
    line-height: 1.9em;
    height: 1.3em;
  }
`;

const Line = styled.div`
  display: table-row;
  font-family: Consolas, Menlo, Monaco, source-code-pro, 'Courier New', monospace;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  user-select: none;
  opacity: 0.5;
  padding-right: 16px;
`;

const LineContent = styled.span`
  display: table-cell;
`;

export {
  AuthorBox,
  AuthorImageBox,
  AuthorName,
  AuthorPicture,
  Container,
  CoverPicture,
  BlogContactButton,
  EmbeddedImage,
  HorizontalRuler,
  NameContainer,
  NameDateWrapper,
  PageTitle,
  PostDate,
  PostFooterText,
  Subtitle,
  TitleContainer,
  TitleGrid,
  VerticalSeparator,
  Pre,
  Line,
  LineNo,
  LineContent,
};

